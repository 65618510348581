@import "src/index";

.container__links {
  row-gap: 10px;
  padding-top: 16px;
  padding-bottom: 32px;
}

.descr {
  color: #5B6073;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-top: 16px;
}

.title {
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
}

.container {
  padding: 32px vw(260) 0 30px;
  flex: 1;
  background-color: #fff;
}

.link {
  color: #007bff;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
}

.row {
  /* Styles for .row */
}

.rowText {
  font-family: 'Nunito', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  padding: 16px 16px 16px 10px;
}

.table {
  padding: 20px 0;
  width: 113%;
}

.tableText {
  font-family:'Nunito', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  padding: 16px 16px 16px 10px;
  text-align: center;
}
