:root {
    --primary-bg-color: #ffffff;
    --rest-primary-bg-color:#fff;
    --rest-accent-bg-color: #b97ef5;
    --rest-primary-text-color:#fff;
    --rest-second-text-color:#9998A0;
    --rest-user-text-color:#7b62f4;
    --rest-input-color:#EAEDF4;
    --rest-button-color:#7b62f4;
    --rest-download-bg:#A069D7;
    --rest-close-btn: rgba(234, 237, 244, 1);
    --rest-close-icon: rgba(158, 163, 177, 1);
    --rest-border-gray: rgba(158, 163, 177, 1);

  }
body{
    margin: 0;
    // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    // 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    // sans-serif;
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    font-family: "Nunito", sans-serif;
    background-color: #FFFFFF;
    
}

h1, h2, h3, h4, h5, h6, p, img {
    margin: 0;
    padding: 0;

}
a{
    text-decoration: none;
    color: #000;
}
ul,li{
    list-style: none;
    margin: 0;
    padding: 0;
}
button {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
}
input[type="text"]{
    font-family: "Nunito", sans-serif;
    font-size: vw(16, 375);
    font-weight: 500;
  }
@function vw($size, $width: 1920) {
    @return ($size / $width) * 100vw;
}

