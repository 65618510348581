@import "src/index";

.main {
  // position:relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;

  padding: vw(32, 375) vw(16, 375);
  margin: 0 auto;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: vw(282, 375);
}

.title {
  font-size: vw(22, 375);
  font-weight: 800;
  text-align: center;
  margin-bottom: vw(32, 375);
}
.downloadContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: vw(282, 375);
  height: vw(310, 375);
  background: radial-gradient(circle, #c489ff 0%, #a366df 100%);
  border-radius: vw(16, 375);
  margin-top: vw(32, 375);
  padding: vw(24, 375) vw(24, 375) 0 vw(24, 375);
}

.downloadTitle {
  font-size: vw(18, 375);
  font-weight: 800;
  text-align: center;

  color: var(--rest-primary-text-color);
  margin-bottom: vw(10, 375);
}

.downloadText {
  font-size: vw(14, 375);
  font-weight: 500;
  text-align: center;
  color: var(--rest-primary-text-color);
  margin-bottom: vw(16, 375);
}

.downloadGoogle {
  width: vw(155, 375);
  height: vw(52, 375);
}
.downloadAppStore{
  width: vw(155, 375);
  height: vw(45, 375);
}
.downloadImage {
  position: absolute;
  bottom: 0;
  width: vw(200, 375);
  height: vw(153, 375);
}

.redConfetti {
  position: absolute;
  top: 5%;
  left: 6%;
}
.redConfettiTwo {
  position: absolute;
  bottom: 23%;
  right: 6%;
}

.turquoiseConfetti {
  position: absolute;
  left: 6%;
  bottom: 10%;
}
.turquoiseConfettiTwo {
  position: absolute;
  top: 55%;
  right: 50%;
}
.turquoiseConfettiThree {
  position: absolute;
  top: 37%;
  right: 5%;
  transform: rotate(110deg);
}
.spiralConfetti {
  position: absolute;
  top: 7%;
  right: 7%;
}
.spiralConfettiTwo {
  position: absolute;
  top: 57%;
  left: 10%;
  transform: rotate(320deg);
}
.dotConfetti {
  position: absolute;
  bottom: 10%;
  right: 7%;
}
.dotConfettiTwo {
  position: absolute;
  top: 30%;
  right: 13%;
}
.lineConfetti {
  position: absolute;
  top: 31%;
  left: 6%;
}
.orangeConfetti {
  position: absolute;
  top: 56%;
  right: 18%;
}

.userContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: vw(136, 375);
  height: auto;

  border-radius: vw(16, 375);
  background-color: #f1f2f7;
  padding: vw(8, 375);
  margin-top: vw(32, 375);
}
.nameContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: vw(5, 375);
}
.userIcon {
  width: vw(24, 375);
  height: vw(24, 375);
  margin-right: vw(6, 375);
}
.userName {
  font-size: vw(18, 375);
  font-weight: 800;
  color: var(--rest-user-text-color);
}

.date {
  font-size: vw(15, 375);
  font-weight: 500;
  color: var(--rest-user-text-color);
}
