@import 'src/index';

.main {
    height: calc(100vh - 50px);
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main_safari {
    justify-content: flex-start;
    row-gap: 64px;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
}

.riddleImage {
    width: 280px;
    height: 280px;
}

.safariImage {
    width: 160px;
    height: 160px;
}

.text {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

.title {
    font-size: 34px;
    font-weight: 800;
    text-align: center;
    line-height: 40px;
    color: #160f5a;
}

.subtitle {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    line-height: 24px;
    color: #9d9d9d;
}

.buttonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7b62f4;
    border-radius: 99px;
    padding: 12px 18px;
    box-shadow: 0px 4px 0px 0px hsl(249, 78%, 98%);
    transition: transform 0.1s ease;
    margin-top: 32px;

    &:active {
        transform: scale(0.95);
    }
}

.button {
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    cursor: none;
    &:active {
        color: #fff;
        transform: scale(0.95);
    }
}

.downloadingArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: bounce 2s infinite;
    align-self: center;
    img {
        width: 32px;
        height: 58px;
    }
}

@keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}
