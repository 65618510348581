@import "src/index";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: vw(32, 375) 0 vw(24, 375) 0;
}

.title {
  font-weight: 800;
  font-size: vw(22, 375);
  text-align: center;
  color: var(--rest-primary-text-color);
}
.text {
  margin-top: vw(16, 375);
  font-size: vw(16, 375);
  text-align: center;
  color: var(--rest-primary-text-color);
}

.input {
  display: block;
  width: vw(310, 375);
  height: vw(52, 375);
  border-radius: vw(16, 375);
  border: vw(1, 375) solid var(--rest-input-color);
  border-bottom: vw(4, 375) solid var(--rest-input-color);
  margin-top: vw(48, 375);
  padding: 0 vw(16, 375);
  font-size: vw(16, 375);
  font-weight: 500;
}


.input:focus {
  outline: none;
}
