@import 'src/index';

.brandingContainer {
  padding: vw(59) vw(320) 0 vw(374);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 9.479vw;
  align-items: center;

  .logoContainer {
    display: flex;
    flex-direction: row;
    gap: vw(10);
    padding-bottom: vw(7);
  }

  @media screen and (max-width: 1711px) {
    .logoContainer {
      gap: 15px
    }
  }

  .logo {
    width: vw(38);
    height: vw(38);
  }

  .logoHeader {
    color: #000;
    font-family: 'Nunito', sans-serif;
    font-size: vw(37);
    font-style: normal;
    font-weight: 800;
    line-height: vw(38.901);
    letter-spacing: -0.361px;
  }

  .interaction {
    display: flex;
    flex-direction: row;
    column-gap: vw(23);

    .interactionItem {
      color: #404040;
      font-family: 'Poppins', sans-serif;
      font-size: vw(18);
      font-style: normal;
      font-weight: 400;
      line-height: vw(24);
      cursor: pointer;
      text-decoration: none;

      .termOfUse {
        margin-right: vw(8);
      }
    }

    .interactionItem:hover {
      color: #C489FF;
    }
  }

  .button {
    display: inline-flex;
    padding: vw(16) vw(32);
    align-items: flex-start;
    gap: vw(10);
    border-radius: 34px;
    background: #C489FF;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: vw(18);
    font-style: normal;
    font-weight: 500;
    line-height: vw(28);
    margin-left: vw(46);
  }
}

@media screen and (max-width: 1711px) {
  .brandingContainer {
    padding: vw(59) vw(278) 0 vw(314);
  }

  .logoContainer {
    gap: 10px;
  }
}

@media screen and (max-width: 600px) {
  .brandingContainer {
    //padding: vw(59) vw(320) 0 vw(374);
    flex-direction: column;
    gap: vw(50, 600);

    .logoContainer {
      padding-bottom: vw(7, 600);
    }

    .logo {
      width: vw(50, 600);
      height: vw(50, 600);
    }

    .logoHeader {
      display: none;
    }

    .interaction {
      flex-direction: column;
      align-items: center;
      gap: vw(50, 600);

      .interactionItem {
        font-size: vw(35, 600);

        .termOfUse {
          margin-right: 0;
        }
      }
    }

    .button {
      padding: vw(36, 600) vw(52, 600);
      font-size: vw(24, 600);
      margin-left: 0;
    }
  }
}
