@import "src/index";

.container {
  padding: vw(32) vw(260) vw(20) vw(0);
  flex: 1;
  background-color: #fff;
  overflow-y: auto;


  .containerLinks {
    gap: 10px;
    padding-top: 16px;
    padding-bottom: 32px;
  }
}
  .descr {
    color: #5B6073;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: vw(16) vw(260) vw(0) vw(30);
  }

  .title {
    font-family: 'Nunito', sans-serif;
    font-size: 20px;
    font-weight: 800;
    padding-left: vw(30);
  }

  .link {
    color: #007bff;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: underline;
    padding-left: vw(30);
  }


