@import "src/index";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: vw(347, 375);
  height: auto;
  background-color: var(--rest-download-bg);
  padding: vw(12, 375) vw(16, 375);
  visibility: visible;
}
.contextContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: vw(330, 375);
  margin: 0 auto;
}
.icon {
  width: vw(16, 375);
  height: vw(16, 375);
  margin-right: vw(16, 375);
  fill: var(--rest-primary-bg-color);
}

.image {
  width: vw(30, 375);
  height: vw(30, 375);
  margin-right: vw(6, 375);
}
.textContainer {
  width: vw(170, 375);
}
.title {
  font-size: vw(13, 375);
  font-weight: 700;
  color: var(--rest-primary-text-color);
}

.text {
  font-size: vw(12, 375);
  font-weight: 400;
  color: var(--rest-primary-text-color);
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: vw(82,375);
  height: vw(26,375);
  padding: vw(4,375) vw(10,375);
  background-color: var(--rest-primary-bg-color);
  border-radius: vw(999,375);
  text-decoration: none;
  font-size: vw(13,375);
  font-weight: 700;
  color: #000;
}
