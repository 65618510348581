@import "src/index";
.main {
  position: relative;
  height: vw(845, 375);
  padding: 0 vw(16, 375);
  margin: 0 auto;
  background-color: var(--rest-accent-bg-color);
}
.restaurantsName {
  font-family: "PP Right Gothic", sans-serif;
  font-weight: 700;
  font-size: vw(25, 375);
  text-transform: uppercase;
  color: var(--rest-primary-text-color);
}
.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: vw(16, 375);
  transition-duration: 250ms;
}
.mainContainerPadding {
  padding-top: vw(86, 375);
}
.mainImage {
  width: vw(350, 375);
  height: vw(210, 375);
  margin-top: vw(32, 375);
}

.bottomText {
  text-align: center;
  font-size: vw(16, 375);
  font-weight: 700;
  color: var(--rest-primary-text-color);
  padding: vw(40, 375) 0;
}
.bottomText > span {
  text-decoration: underline;
}
