.road {
    height: 1450px;
    position: relative;
    margin-bottom: 10px;
}

.roadImage {
    height: 100%;
    max-width: 396px;
    width: 100%;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    top: 0;
}

.maskot {
    position: absolute;
    left: -5%;
    top: 900px;
    width: 232px;
    height: 122px;
}
