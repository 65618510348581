@import "src/index";

.header {
  height: 64px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.logo {
  width: 121.9px;
  height: 18px;
}
