@import "src/index";

.button{
    display: block;
    width: vw(343, 375);
    
    height: vw(52, 375);
    border-radius: vw(22, 375);
    border-bottom: vw(4, 375) solid #5741C2;
    background-color: #7B62F4;
    padding:vw(14, 375) vw(10, 375);
    margin: 0 auto;
    font-size: vw(16, 375);
    font-weight: 700;
    color: #fff;
    transition: all 0.5s;
}
.disable{
    color: #9A89ED;
    border: none;
   
}
