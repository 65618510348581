@import "src/index";

.imageContainer {
  position: relative;
  border: vw(1, 375) dashed var(--rest-input-color);
  border-radius: vw(16, 375);
  padding: vw(8, 375);
  width: vw(266, 375);
  height: vw(266, 375);
}

.mainImage {
  border-radius: vw(16, 375);
  width: 100%;
  height: 100%;
}
.icon {
  position: absolute;
  top: vw(-12, 375);
  right: vw(-12, 375);
  width: vw(100, 375);
  height: vw(100, 375);
}

.motion {
  perspective: "1500px";
  transform-style: "preserve-3d";
  width: vw(282, 375);
  height: vw(282, 375);
}
