@import "index";


.App {
  // padding-bottom: vw(131);

  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: vw(78) vw(162) vw(0) vw(374);
    position: relative;

    .mainContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: vw(53);
      width: vw(600);
      height: 100%;
      padding-top: vw(108);
      padding-left: vw(17);

      h1 {
        color: #000;
        font-family: 'Nunito', sans-serif;
        font-size: vw(64);
        font-style: normal;
        font-weight: 800;
        line-height: vw(38.901);
        letter-spacing: -0.361px;
      }

      .mainTextContainer {
        padding-left: vw(10);
        margin-bottom: vw(20);
      }

      p {
        color: #565656;
        font-family: 'Nunito', sans-serif;
        font-size: vw(20);
        font-weight: 400;
        opacity: 0.8;
      }

      .mainText {
        margin-bottom: vw(27);
      }
    }

    .mainImage {
      width: auto;
      height: vw(736);
      object-fit: cover;
      margin-bottom: vw(101);
    }

    .buttonContainer {
      display: flex;
      flex-direction: row;
      gap: vw(31);
      margin-left: vw(10);
    }

    .storeButton {
      display: flex;
      width: vw(216);
      height: auto;
      //height: vw(49.6);
      transform: rotate(0deg);
      padding-bottom: 0;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    .elements {
      position: absolute;
      top: vw(10);
      left: 0;
      width: vw(84);
    }

    .CircleBlue {
      position: absolute;
      width: vw(8);
    }

    .CircleGreen {
      position: absolute;
      top: vw(80);
      right: vw(650);
      width: vw(20);
    }

    .CircleGreenSmall {
      position: absolute;
      top: vw(770);
      left: vw(890);
      width: vw(10)
    }

    .CircleRed {
      position: absolute;
      top: vw(310);
      left: vw(1080);
      width: vw(14);
    }

    .CircleRedTwo {
      position: absolute;
      top: vw(450);
      left: vw(112);
      width: vw(14)
    }

    @media screen and (max-width: 1711px) {
      .CircleGreen {
        top: vw(95);
        right: vw(700);
      }
      .CircleGreenSmall {
        top: vw(790);
        left: vw(850);
      }
    }
  }

  @media screen and (max-width: 1711px) {
    .main {
      padding: vw(100) vw(218) 0 vw(324);
    }

  }

  @media screen and (max-width: 600px){
    .logoContainer {
      display: flex;
      justify-content: space-around;
      padding-bottom: vw(7, 600);
      padding-top: vw(59);

    }

    .logo {
      width: vw(50, 600);
      height: vw(50, 600);
    }

    .logoHeader {
      display: none;
    }

    .main {
      flex-direction: column;
      padding: vw(20, 600) vw(64, 600) vw(0) vw(64, 600);

      .mainContent {
        gap: vw(53, 600);
        width: vw(600);
        height: 100%;
        padding-top: vw(108, 600);
        padding-left: 0;

        h1 {
          font-size: vw(64, 600);
          margin-left: vw(67, 600);
        }

        .mainTextContainer {
          padding-left: 0;
          margin-bottom: vw(0);
          width: vw(460, 600);
        }

        p {
          font-family: 'Nunito', sans-serif;
          font-size: vw(30, 600);
        }

        .mainText {
          margin-bottom: vw(57, 600);
        }
      }

      .mainImage {
        width: auto;
        height: vw(636, 600);
        margin-left: vw(-45, 600);
        margin-bottom: vw(101);
      }

      .buttonContainer {
        flex-direction: row;
        gap: vw(31, 600);
        margin-left: vw(10);
        margin-bottom: vw(100, 600);
      }

      .storeButton {
        width: vw(216, 600);
      }

      .elements {
        position: absolute;
        top: vw(350, 600);
        left: 0;
        width: vw(84, 600);
      }

      .CircleBlue {
        position: absolute;
        width: vw(10, 600);
      }

      .CircleGreen {
        top: vw(460, 600);
        right: vw(30, 600);
        width: vw(20, 600);
      }

      .CircleGreenSmall {
        position: absolute;
        top: vw(870, 600);
        left: vw(80, 600);
        width: vw(10, 600)
      }

      .CircleRed {
        position: absolute;
        top: vw(610, 600);
        left: vw(440, 600);
        width: vw(14, 600);
      }

      .CircleRedTwo {
        position: absolute;
        top: vw(450);
        left: vw(112);
        width: vw(14, 600)
      }

    }

  }

}
