@import "src/index";
.container {
  padding: vw(32, 375) 0;
}
.box {
  padding: vw(26, 375) vw(16, 375);
  background-color: var(--rest-primary-bg-color);
  // max-width: 311px;
  margin: 0 auto;
  border-radius: vw(16, 375);
}
.title {
  font-size: vw(26, 375);
  font-weight: 700;
}
.text {
  font-size: vw(18, 375);
  font-weight: 300;
  margin-top: vw(16, 375);
}
.title_container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: vw(17, 375);
}
.inputTitle {
  font-size: vw(18, 375);
  font-weight: 700;

}
.hintContainer{
  display: flex;
  align-items: center;
  border-bottom: 1px dashed var(--rest-button-color);
}
.hintText{
  color: var(--rest-button-color);
  margin-left: vw(6, 375);
}
.icon{
  width: vw(18, 375);
  height: vw(18, 375);
}
.input {
  display: block;
  width: vw(290, 375);
  height: vw(52, 375);
  border-radius: vw(8, 375);
  border: none;
  background-color: var(--rest-input-color);
  margin-top: vw(8, 375);
  padding-left: vw(16, 375);
  font-size: vw(16, 375);
  font-weight: 400;
}

.input::placeholder {
  font-size: vw(16, 375);
  font-weight: 400;
}
.input:focus {
  outline: none;
}
