@import "src/index";

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 vw(16, 375);
  margin: 0 auto;
  background-color: #b97ef5;
 
}
.mainImage{
    width: vw(230, 375);
    height: vw(220, 375);
}
.title {
  font-size: vw(22, 375);
  font-weight: 800;
  color: #fff;
  margin-top: vw(40, 375);
}
