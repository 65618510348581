@import "src/index";

.main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 vw(16, 375);
  margin: 0 auto;
  background-color: #b97ef5;
}
.logo{
    width: vw(170, 375);
    height: vw(240, 375);
}
