@import "src/index";

.main {
  position: relative;
  padding: vw(120, 375) vw(16, 375);
  margin: 0 auto;
}
.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: vw(93, 375);
  
}
.mainImage {
  width: vw(230, 375);
  height: vw(220, 375);
}

.title {
  font-size: vw(22, 375);
  font-weight: 800;
  margin-top: vw(40, 375);
}

.text {
  width: vw(300, 375);
  font-size: vw(16, 375);
  font-weight: 500;
  color: var(--rest-second-text-color);
  text-align: center;
  margin-top: vw(16, 375);
}
