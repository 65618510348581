.downloadBanner {
    height: 360px;
    background: radial-gradient(
        125.47% 125.47% at 50.15% 100%,
        #c489ff 0%,
        #a366df 100%
    );
    border-radius: 16px;
    box-shadow: 0px 4px 0px 0px #9865cc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    row-gap: 24px;
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;
}

.banner {
    width: 176px;
    height: 52px;
    z-index: 2;
}

.title {
    font-size: 18px;
    font-weight: 800;
    color: #fff;
    margin-top: 24px;
}

.bannerWrapper {
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.maskot {
    width: 200px;
    height: 153px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}

.confeti {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
}
