.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border-radius: 16px;
    row-gap: 16px;
}

.title {
    font-size: 28px;
    font-weight: 700;
    color: #fff;
    text-align: center;
}

.subtitle {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-align: center;
}

.pink {
    background: radial-gradient(
        125.47% 125.47% at 50.15% 100%,
        #c489ff 0%,
        #a366df 100%
    );
    box-shadow: 0px 3px 0px 0px #9865cc;
}

.green {
    background: #72c9c0;
    box-shadow: 0px 3px 0px 0px #4d8a84;
}
